import api from "../axios_service.js";

const fetchUserPerfis = async (user_id) => {
  const response = await api.get(`/gestor/user-perfis?user_id=${user_id}`);
  return response.data.result;
};

const fetchPerfisLivres = async (user_id) => {
  const response = await api.get(`/gestor/perfis-livres?user_id=${user_id}`);
  return response.data.result;
};

// const putCargo = (id, body) => {
//   return api.put(`/gestor/cargos/${id}`, body);
// }

const postUserPerfis = (body) => {
  return api.post("/gestor/user-perfis", body);
}

const deleteUserPerfil = (id) => {
  return api.delete(`/gestor/user-perfis/${id}`);
}

// const syncJornadas = (body) => {
//   return api.post("/gestor/sincronizar-jornadas-trabalho", body);
// }

export { fetchUserPerfis, fetchPerfisLivres, postUserPerfis, deleteUserPerfil };