<template>
  <v-dialog
    v-model="dialogUsuario"
    @click:outside="$emit('update:dialogUsuario', false)"
    @keydown.esc="$emit('update:dialogUsuario', false)"
    scrollable
    width="700"
  >
    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-4">
        {{ edit ? "Editar" : "Adicionar" }} Usuário
        <v-spacer></v-spacer>
        <v-btn
          depressed
          exact
          icon
          @click="$emit('update:dialogUsuario', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-tabs v-model="tab">
        <v-tab>Usuário</v-tab>
        <v-tab>Usuário Perfis</v-tab>
        <v-tab v-if="edit && isGestor">Permissão</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text class="px-5 py-6">
            <v-form ref="form" v-model="validForm" lazy-validation>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="usuario.name"
                    label="Nome"
                    dense
                    hide-details
                    required
                    :rules="formRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-details
                    dense
                    v-model="usuario.celular"
                    label="Celular"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="usuario.email"
                    label="E-mail"
                    hide-details
                    dense
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="usuario.password"
                    :label="edit ? 'New password' : 'Password'"
                    dense
                    hide-details
                    :required="edit ? false : true"
                  >
                    <!-- :rules="edit ? [] : emailRules" -->
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="usuario.password_confirmation"
                    label="Confirmar password"
                    dense
                    hide-details
                    :required="edit ? false : true"
                  >
                    <!-- :rules="edit ? [] : emailRules" -->
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-combobox
                    v-model="selectedCargo"
                    :items="cargos"
                    item-text="descricao"
                    label="Cargos"
                    :rules="formRules"
                    required
                    hide-details
                    item-value="id"
                    return-object
                  ></v-combobox>
                </v-col>

                <v-col v-if="edit" cols="12" md="6">
                  <v-text-field dense readonly value="Status:">
                    <template v-slot:append>
                      <v-switch
                        v-model="usuario.status"
                        :false-value="0"
                        :true-value="1"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4">
            <v-btn outlined @click="$emit('update:dialogUsuario', false)">
              {{ edit ? "voltar" : "cancelar" }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="edit ? editarUsuario() : adicionarUsuario()"
              :disabled="!validForm"
              color="secondary"
              class="white--text"
            >
              {{ edit ? "Editar" : "Adicionar" }}
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item>
          <v-card-text>
            <v-alert outlined>
              <h4>Observação:</h4>

              <p class="mt-3">
                Por enquanto está desabilidade adicionar perfil de
                <strong>COLABORADOR</strong> ou <strong>CLIENTE</strong> como
                perfil extra, pois usuários com estes perfis devem ser criados e
                vinculados apartir de suas respectivas telas (Colaboradores e
                Clientes). <br />
                Em breve esse sistema sera ajustado para que seja possível
                adicionar por aqui.
              </p>
            </v-alert>

            <v-card outlined>
              <div class="d-flex px-3 py-2">
                <span class="text-subtitle-1">Perfis</span>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialogPerfis" width="100%" max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      align="center"
                      color="secondary"
                      class="white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Adicionar
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-subtitle-1 accent lightgray">
                      Adicionar Perfil
                      <v-spacer></v-spacer>
                      <v-btn icon @click="dialogPerfis = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="py-10">
                      <v-combobox
                        v-if="perfisLivres.length"
                        hide-selected
                        v-model="selectedPerfil"
                        :items="perfisLivres"
                        label="Perfil"
                        item-text="descricao"
                        item-value="id"
                        dense
                        return-object
                      ></v-combobox>
                      <div v-else class="text-center">
                        Todos os perfis ja foram adicionados!
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="!selectedPerfil"
                        @click="createUserPerfil"
                        color="secondary"
                        depressed
                        class="white--text"
                        small
                      >
                        Adicionar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <v-divider></v-divider>
              <v-card-text class="pa-3">
                <v-chip
                  v-for="(user_perfil, index) in userPerfis"
                  :key="index"
                  class="ma-1"
                  label
                  close
                  close-icon="mdi-delete"
                  @click:close="delUserPerfil(user_perfil.id)"
                >
                  <v-icon left>mdi-account</v-icon>
                  {{ user_perfil.perfil }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <UsuarioPermissoes v-if="edit && isGestor" :usuarioId="usuario.id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { fetchCargos } from "@/api/gestor/cargos.js";
import { postUsuario, putUsuario, getUsuario } from "@/api/admin/users.js";
import {
  deleteUserPerfil,
  fetchPerfisLivres,
  fetchUserPerfis,
  postUserPerfis,
} from "@/api/gestor/users_perfis.js";

export default {
  name: "AddUsuario",

  components: {
    UsuarioPermissoes: () => import("./UsuarioPermissoes.vue"),
  },

  props: {
    dialogUsuario: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      dialogPerfis: false,
      tab: 1,
      formRules: [inputRequired],
      emailRules: [validaEmail],
      validForm: true,
      loading: false,
      usuario: {
        name: "",
        cargo: null,
      },
      cargos: [],
      perfils: [],
      selectedCargo: null,
      selectedPerfil: null,
      userPerfis: [],
      perfisLivres: [],
    };
  },

  computed: {
    isGestor() {
      return this.userPerfis.some((perfil) => perfil.perfil == "GESTOR");
    },
  },

  watch: {
    selectedCargo() {
      if (this.selectedCargo) {
        this.usuario.cargo = this.selectedCargo.id;
      }
    },
    dialogPerfiss() {
      if (this.dialogPerfis) {
        this.getPerfisLivres();
      }
    },
  },

  methods: {
    async fetchUsuario() {
      const response = await getUsuario(this.item.id);
      this.usuario = response;
    },

    adicionarUsuario() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let usuario = { status: 1, perfil_editar: true };
        usuario.name = this.usuario.name;
        usuario.email = this.usuario.email;
        usuario.cargo = this.usuario.cargo;
        if (this.usuario.password === this.usuario.password_confirmation) {
          usuario.password = this.usuario.password;
          usuario.password_confirmation = this.usuario.password_confirmation;
        } else {
          this.$toast.error("Senhas diferentes");
          this.loading = false;
          return;
        }
        if (this.usuario.celular) {
          usuario.celular = this.usuario.celular;
        }
        postUsuario(usuario)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Usuario criado");
              this.loading = false;
              this.$emit("update:dialogUsuario", false);
              this.$emit("fetch-usuario");
            }
          })
          .catch((error) => {
            if (error.response.data.email) {
              this.$toast.error("Esse E-mail já existe!");
              this.loading = false;
            }
          });
      }
    },

    editarUsuario() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let usuario = {};
        usuario.id = this.usuario.id;
        usuario.name = this.usuario.name;
        usuario.email = this.usuario.email;
        usuario.cargo = this.usuario.cargo;
        usuario.status = this.usuario.status;
        usuario.perfil_editar = true;
        if (this.usuario.password === this.usuario.password_confirmation) {
          usuario.password = this.usuario.password;
          usuario.password_confirmation = this.usuario.password_confirmation;
        } else {
          this.$toast.error("Senhas diferentes");
          let block = true;
          this.loading = false;
          return block;
        }
        if (this.usuario.celular) {
          usuario.celular = this.usuario.celular;
        }
        putUsuario(usuario.id, usuario)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Usuario editado");
              this.loading = false;
              this.$emit("update:dialogUsuario", false);
              this.$emit("fetch-usuario");
            }
          })
          .catch((error) => {
            if (error.response.data.email) {
              this.$toast.error("Esse E-mail já existe!");
              this.loading = false;
            }
          });
      }
    },

    createUserPerfil() {
      postUserPerfis({
        user_id: this.item.id,
        perfil_id: this.selectedPerfil.id,
      }).then((response) => {
        if (response.status === 201) {
          this.dialogPerfis = false;
          this.selectedPerfil = null;
          this.getUserPerfis();
        }
      });
    },

    delUserPerfil(id) {
      this.loading = true;
      deleteUserPerfil(id)
        .then(() => {
          this.getUserPerfis();
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getUserPerfis() {
      this.userPerfis = await fetchUserPerfis(this.item.id);
    },

    async getPerfisLivres() {
      this.perfisLivres = await fetchPerfisLivres(this.item.id);

      this.perfisLivres.map((perfil) => {
        if (perfil.descricao == "COLABORADOR") {
          perfil.disabled = true;
        }
        if (perfil.descricao == "CLIENTE") {
          perfil.disabled = true;
        }

        return perfil;
      });
    },

    getCargos() {
      return fetchCargos()
        .then((response) => {
          this.cargos = response;
          if (this.edit) {
            this.cargos.forEach((item) => {
              if (this.item.cargo === item.id) {
                this.selectedCargo = item;
              }
            });
          }
        })
        .finally(() => {});
    },
  },
  async mounted() {
    this.loading = true;
    if (this.edit) {
      await this.fetchUsuario();
    }
    await this.getCargos();
    await this.getUserPerfis();
    await this.getPerfisLivres();

    this.loading = false;
  },
};
</script>

<style></style>
